import React from 'react'
import Layout from "../components/Layout"
import Datenschutz from "../components/Datenschutz"
import {SEO} from "../components/Seo";
import {Helmet} from "react-helmet";

const datenschutz = () => {
    return (
        <Layout>
            <Helmet>
                <title>Datenschutz | Business Intelligence Warehouse | SAP-Wissen wie ein Espresso (Bücher & E-Books)</title>
            </Helmet>
            <Datenschutz/>
        </Layout>
    )
}

export default datenschutz

export const Head = () => (
    <SEO/>
)
